.field-map-info {
  background: rgba(255, 255, 255, 0.7);
  padding: 5px 6px;
  width: 251px;
  min-height: 200px;
  font-weight: 700;

  &__block {
    display: flex;
  }

  &-wrap {
    position: absolute;
    left: 10px;
    z-index: 999;
    top: 110px;
    padding-top: 18px;
  }

  &__date {
    display: flex;
  }

  .map-draw-area_map-view + & {
    top: 150px;
  }

  &__ndvi {
    margin-top: 12px;
  }

  &__show-all {
    margin-top: 4px;
    cursor: pointer;
    text-decoration: underline;
  }

  & .field-rotation-info__crop {
    margin-left: 1px;
  }

  &__title {
    margin-bottom: 4px;
  }

  &__divider-bottom {
    height: 1px;
    background-color: black;
    width: 100%;
    margin: 10px 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_center {
      align-items: center;
      display: flex;
      text-decoration: underline;
      cursor: pointer;
    }

    &_pointer {
      cursor: pointer;
    }
  }
}
