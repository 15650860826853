.rosreestr {
  display: flex;
  flex-direction: column;
  gap: 36px;

  &__block {
    flex: 1;
  }

  &__icon {
    width: 24px;
    cursor: pointer;
  }

  &-info {
    margin-bottom: 4px;
    &-block {
      &__text {
        &_bold {
          font-weight: 500;
        }
      }

      &__copy {
        margin-left: 6px;
      }
    }
  }
}

.cadaster-table {
  &_fix_margin .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    margin-bottom: -36px;
  }
}