$left-width: 218px;
$right-width: 156px;
$block-padding: 8px;

.control-works-panel {
  height: 90px;
  &__left {
    width: $left-width;
    display: flex;
    flex-direction: column;
    padding: $block-padding;
    height: 100%;
  }
  &__center {
    flex: 1;
    height: 100%;
    width: calc(100% - #{$left-width} - #{$right-width});
    overflow-x: auto;
    padding: $block-padding;
  }
  &__right {
    flex-direction: column;
    width: $right-width;
    height: 100%;
    display: flex;
    padding: 0 6px;
    justify-content: center;
  }

  &__add-btn {
    margin-bottom: 5px;
  }
}

.control-works-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__date {
    width: 110px;
  }

  > button {
    margin-left: 6px;
  }

  .form-group {
    margin-bottom: 8px;
  }
}

.control-works-list-item-work {
  &__info {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.control-works-panel-grouped {
  display: flex;
  flex-direction: row;
  flex: 1;

  &_mrr {
    margin-right: 14px;
  }
}

.control-works-panel-grouped-empty {
  height: 100%;
  padding: 8px;
  flex: 1;
}

.control-works-panel-grouped-item {
  &__block {
    display: flex;
  }
}

.control-works-panel-grouped-item-crop {
  display: flex;
  align-items: center;
  &__name {
    white-space: nowrap;
  }
  &__circle {
    content: '';
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    width: 10px;
    height: 10px;
    line-height: 10px;
    border-radius: 50%;
    margin-left: 3px;
    margin-right: 11px;
    cursor: pointer;

    &_empty {
      border: 1px solid black;
    }
  }
}

.control-works-panel-grouped-item-work-type {
  display: flex;
  &__name {
    white-space: nowrap;
  }
  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: #069c61;
  }
}

.control-works-panel-grouped-item-crop-info {
  &__name {
    white-space: nowrap;
  }
}
