.field-satellite-map {
  &__layout {
    flex-wrap: wrap;
    gap: 2px;
    background: linear-gradient(to right, #ddd 205px, #fff 205px, #fff calc(50% - 1px), #ddd calc(50% - 1px), #ddd calc(205px + 50%), #fff calc(205px + 50%));

    & .mapboxgl-control-container {
      display: none !important;
    }

    & .sat-container {
      width: calc(100% - 19px);
    }
  }

  &__toggle-view-btn {
    position: fixed;
    bottom: 12px;
    right: 13px;
    z-index: 1000;
    width: 206px;
    height: 79px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
  }

  &__item {
    width: calc(50% - 1px);
    display: flex;
    height: 50%;
    background: #fff;

    & .sat-scale {
      min-width: auto;
    }

    & .sat-container {
      bottom: 2px;
    }
  }
}
